import logo from "./assets/logo.png";
import './App.css';

function App() {
  return (
    <div className="App">
    <div className="App-header">
      <a href="https://softwareosity.com">
        <img src={logo} className="App-logo" alt="logo" />
      </a>
      
      <p className='tagline'> 
        Building Web3 & Enterprise Applications To Multiply Your Business Revenues.
      </p>
  
      <br/>
      <p className='tagline'> 
        Careers At Softwareosity - Coming Soon!
      </p>

    </div>
  </div>
  );
}

export default App;
